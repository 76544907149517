import { graphql, useStaticQuery } from "gatsby"

import CustomLink from "../common/components/CustomLink"
import EntitiesContainer from "../common/components/EntitiesContainer"
import HeroContainer from "../common/components/HeroContainer"
import Layout from "../layout/components/Layout"
import React from "react"
import { StyledHeroTitle } from "../common/components/styled/hero"

function LegalLinks() {
  const { allContentfulLegal } = useStaticQuery(
    graphql`
      {
        allContentfulLegal(filter: { isCompetition: { eq: true } }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )
  const { edges } = allContentfulLegal

  return (
    <ul className="list-reset mb-6 text-base font-light">
      {edges.map(({ node }) => (
        <CustomLink url={`/${node.slug}`} key={node.slug}>
          <h2 className="text-medium mb-2 text-lg text-left mt-0">
            {node.title}
          </h2>
        </CustomLink>
      ))}
    </ul>
  )
}

export default () => {
  return (
    <Layout>
      <HeroContainer>
        <StyledHeroTitle>Competition Terms and Conditions</StyledHeroTitle>
      </HeroContainer>
      <EntitiesContainer>
        <div className="flex flex-col w-full mx-6 md:mx-2">
          <LegalLinks />
        </div>
      </EntitiesContainer>
    </Layout>
  )
}
